import styled from 'styled-components';
import { Modal } from 'antd';

export const RegistrationFormWrapper = styled.div`
  label,
  small,
  span {
    color: #878787;
  }
  label {
    display: block;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.3;
    margin-bottom: 10px;

    sup {
      color: red;
      font-size: 100%;
      top: 0;
    }
  }

  .ant-btn {
    height: auto;
  }
  .ant-checkbox-group {
    .ant-checkbox-wrapper {
      display: flex;
    }
  }

  .ant-input {
    background-color: #fff !important;
    color: #333 !important;
    border-radius: 2px !important;
    // border: 1px solid #737C80 !important;
  }
  button {
    background-color: rgba(48, 185, 127, 1);
    text-decoration: none;
    border-color: transparent;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.3s linear;
    span {
      color: #ffffff;
    }
  }

  .ant-picker {
    width: 100%;
    height: 44px !important;
    border-radius: 5px !important;
    // border: 1px solid #737C80 !important;
  }
  input {
    color: #333 !important;
  }
  span {
    line-height: 29px;
    font-size: 16px;
  }

  .ant-checkbox-wrapper {
    display: flex;
    margin-left: 8px;
  }

  .ant-radio-wrapper {
    display: flex;
    align-items: baseline;
  }

  .image-row {
    margin-top: 20px;
    .uploaded-file {
      width: 100px;
      height: 100px;
      background: #737c805e;
      justify-content: center;
      align-items: center;
      display: flex;

      svg {
        color: blue;
      }
    }

    .show-item {
      overflow: hidden;
      width: 100px;
      margin-right: 20px;

      p {
        text-overflow: ellipsis;
        margin: 0;
        width: 100px;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .uploaded-image {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        max-height: 100px;
      }

      .icon-row {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon-style {
          background-color: transparent;
          box-shadow: none;
          border: none;
          display: none;
        }
      }

      .icon-row:hover {
        background-color: #100f0f8a;
        .icon-style {
          display: inline-block;
        }
      }
    }
  }
  .ant-form-item-has-error
    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
    .ant-select-selector {
    background-color: #ffffff;
    border-color: #ff4d4f !important;
  }
  .ant-form-item-has-error .ant-input-number,
  .ant-form-item-has-error .ant-picker {
    background-color: #ffffff;
    border-color: #ff4d4f;
  }
  .ant-form-item-has-error .ant-input-number:not([disabled]):hover,
  .ant-form-item-has-error .ant-picker:not([disabled]):hover {
    background-color: #ffffff;
    border-color: #ff4d4f;
  }
`;

export const ModalWrapper = styled(Modal)`
  .ant-modal-body {
    .img {
      width: 100%;
      height: auto;
    }
  }
`;
export const UploadImageWrapper = styled.div`
  position: relative;

  .icon-spin {
    position: absolute;
    z-index: 3;
    top: 23%;
    left: 49%;
  }
`;
