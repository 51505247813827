import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  DatePicker,
  Select,
} from 'antd';
import {
  LIST_NATIONALITY,
  REGISTERED_STATE,
  SPECIES,
  STATE,
} from 'configs/localData';
import { apiRegister } from '../../../api/register';
import React, { useState } from 'react';
import { RegistrationFormWrapper } from './style';
import UploadFile from './UploadFile';

const FormItem = Form.Item;
const { Option } = Select;

const RegistrationForm: FC = () => {
  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [veterinaryRegistration, setVeteRegis] = useState([]);
  const [driverLicense, setdriverLicense] = useState([]);
  const [form] = Form.useForm();
  const onFinish = (values) => {
    setLoading(true);
    const dataRegister = {
      firstName: values.firstName,
      middleName: values?.middleName,
      lastName: values.lastName,
      preferredName: values?.preferredName,
      dob: values?.dob?.format('DD/MM/YYYY'),
      email: values.email,
      phoneNumber: values.phone,
      alternativePhoneNumber: values?.alternativePhoneNumber,
      address: {
        addressLine1: values.address1,
        addressLine2: values?.addressLine2,
        suburb: values.suburb,
        state: values.state,
        postCode: values.postcode,
        country: values.nationality,
      },
      registeredState: values.registeredState,
      veterinaryRegistration: veterinaryRegistration,
      registrationExpiryDate: values?.expiryDate?.format('DD/MM/YYYY'),
      bio: values.bio,
      qualifications: values.qualifications,
      supportedSpecies: [
        ...values.species,
        ...(values.otherSpecies ? [values.otherSpecies] : []),
      ],
      driverLicense: driverLicense,
      abn: values.ABN,
      gstRegistered: values.GST,
      bankInformation: {
        holderName: values.holderName,
        bsb: values.BSB,
        accountNumber: values.accountNumber,
      },
      note: values?.note,
      isPublicProfile: values?.isPublicProfile,
      registrationNumber: values?.registrationNumber,
    };
    apiRegister(dataRegister).finally(() => {
      setLoading(false);
      form.resetFields();
      setVeteRegis([]);
      setdriverLicense([]);
    });
  };
  async function uploadFile(
    uploadUrl: string,
    file: File,
    fields: any,
    docType: string
  ) {
    if (file?.type === 'attachment') {
      file.type = file?.mime;
    }
    delete file?.mime;
    const formData = new FormData();
    Object.keys(fields).forEach((key) => {
      formData.append(key, fields[key]);
    });

    formData.append('file', file);

    return fetch(uploadUrl, {
      method: 'POST',
      body: formData,
    }).then(() => {
      if (docType === 'veterinary') {
        setVeteRegis([...veterinaryRegistration, fields.key]);
      } else {
        setdriverLicense([...driverLicense, fields.key]);
      }
    });
  }
  return (
    <RegistrationFormWrapper className="wrapper">
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{ isPublicProfile: true }}
      >
        <label>
          Name <sup>*</sup>
        </label>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <FormItem
              name="firstName"
              rules={[
                { required: true, message: 'Please input your first name!' },
              ]}
            >
              <Input placeholder="First Name" />
            </FormItem>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <FormItem name="middleName">
              <Input placeholder="Middle Name (Optional)" />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <FormItem
              name="lastName"
              rules={[
                { required: true, message: 'Please input your last name!' },
              ]}
            >
              <Input placeholder="Last Name" />
            </FormItem>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <FormItem name="preferredName">
              <Input placeholder="Preferred Name (Optional)" />
            </FormItem>
          </Col>
        </Row>
        <label>
          Date of birth<sup>*</sup>
        </label>
        <Row>
          <Col xs={12} sm={12} md={8} lg={8} xl={8}>
            <FormItem
              name="dob"
              rules={[
                { required: true, message: 'Please input your date of birth!' },
              ]}
            >
              <DatePicker format="DD/MM/YYYY" />
            </FormItem>
          </Col>
        </Row>
        <label>
          Email <sup>*</sup>
        </label>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <FormItem
              name="email"
              rules={[
                { required: true, message: 'Please input your email!' },
                { type: 'email', message: 'This is not a valid email' },
              ]}
            >
              <Input />
            </FormItem>
          </Col>
        </Row>
        <label>
          Phone number <sup>*</sup>
        </label>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <FormItem
              name="phone"
              rules={[
                { required: true, message: 'Please input your phone number!' },
              ]}
            >
              <Input />
            </FormItem>
          </Col>
        </Row>
        <label>Alternative phone number</label>
        <Row>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <FormItem name="alternativePhoneNumber">
              <Input />
            </FormItem>
          </Col>
        </Row>
        <label>
          Address <sup>*</sup>
        </label>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
            <FormItem
              name="address1"
              rules={[
                { required: true, message: 'Please input your address!' },
              ]}
            >
              <Input placeholder="Address Line 1" />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
            <FormItem name="addressLine2">
              <Input placeholder="Address Line 2 (optional)" />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={12} sm={12} md={8} lg={8} xl={8}>
            <FormItem
              name="suburb"
              rules={[{ required: true, message: 'Please input your suburb!' }]}
            >
              <Input placeholder="Suburb" />
            </FormItem>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8} xl={8}>
            <FormItem
              name="state"
              rules={[{ required: true, message: 'Please select your state!' }]}
            >
              <Select placeholder="Select state">
                {STATE.map((e) => (
                  <option value={e.value}>{e.name}</option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={12} sm={12} md={8} lg={8} xl={8}>
            <FormItem
              name="postcode"
              rules={[
                { required: true, message: 'Please input your postcode!' },
              ]}
            >
              <Input placeholder="Postcode" />
            </FormItem>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8} xl={8}>
            <FormItem
              name="nationality"
              rules={[{ required: true, message: 'Please select country!' }]}
            >
              <Select placeholder="Select country">
                <Option value="AU">Australia</Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <label>
          In which state or territory are you registered as a veterinarian?{' '}
          <sup>*</sup>
        </label>
        <Row gutter={16}>
          <Col xs={12} sm={12} md={8} lg={8} xl={8}>
            <FormItem
              name="registeredState"
              rules={[
                { required: true, message: 'Please input registered state!' },
              ]}
            >
              <Select placeholder="Select registered state">
                {REGISTERED_STATE.map((e) => (
                  <Select.Option value={e.value}>{e.name}</Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>
        <label>
          Upload a copy or a screeenshot of your veterinary registration
          <sup>*</sup>
        </label>
        <p style={{ color: '#878787' }}>
          Veterinary registration file upload can accept pdf and word docs as
          well.
        </p>
        <Row>
          <Col span={24}>
            <FormItem name="veterinary" valuePropName="fileList">
              <UploadFile
                maxFiles={2}
                uploadFile={uploadFile}
                setData={setVeteRegis}
                typeFile="file"
                loading={loadingImage}
                setLoading={setLoadingImage}
              />
            </FormItem>
          </Col>
        </Row>
        <label>
          Registration number <sup>*</sup>
        </label>
        <Row>
          <Col xs={12} sm={12} md={8} lg={8} xl={8}>
            <FormItem
              name="registrationNumber"
              rules={[
                {
                  required: true,
                  message: 'Please input your registration number!',
                },
              ]}
            >
              <Input />
            </FormItem>
          </Col>
        </Row>
        <label>
          Registration expiry date <sup>*</sup>
        </label>
        <Row>
          <Col xs={12} sm={12} md={8} lg={8} xl={8}>
            <FormItem
              name="expiryDate"
              rules={[
                {
                  required: true,
                  message: 'Please input the expiry date!',
                },
              ]}
            >
              <DatePicker format="DD/MM/YYYY" />
            </FormItem>
          </Col>
        </Row>
        <label>
          Please write a bio (see the examples below for suggested content){' '}
          <sup>*</sup>
        </label>
        <Row>
          <Col span={24}>
            <FormItem
              name="bio"
              rules={[{ required: true, message: 'Please input your bio!' }]}
            >
              <Input.TextArea rows={4} />
            </FormItem>
            <FormItem name="isPublicProfile" valuePropName="checked">
              <Checkbox>
                Bios are displayed in the app and on the website.
              </Checkbox>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <h3>Suggested content for the bio</h3>
            <i>
              <span>
                Your current employment and position.
                <br />
                When and from which veterinary school did you graduate?
                <br />
                What veterinary work have you been doing since graduation?
                <br />
                Any unique veterinary experiences i.e. India? The Falkland
                Islands?
                <br />
                Any relevant post-graduate veterinary qualifications? I.e.
                internship? University practitioner-in-residence? Master’s
                degree?
                <br />
                Any special veterinary interests?
              </span>
            </i>
            <h3>Example bio</h3>
            <span>
              Kendall Smith-Jones BVSc MVS PhD
              <br />
              Senior Veterinarian at All Creatures Surgical in Goolongeel,
              Victoria, Australia
              <br />
              Graduated University of Cotswald in 1997 with second class
              honours. Have worked in small and large animal practice in
              Australia and the UK and did a 12-month stint in India
              volunteering at a street dog rescue clinic. I have post graduate
              qualifications in small animal medicine and have a special
              interest in oncology. I am happy to provide advice on all
              companion animal species including fish..
            </span>
          </Col>
        </Row>
        <br />
        <label>
          Your qualification(s) <sup>*</sup>
        </label>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
            <FormItem
              name="qualifications"
              rules={[
                {
                  required: true,
                  message: 'Please input your qualification(s)!',
                },
              ]}
            >
              <Input />
            </FormItem>
            <small>e.g. BVSc MVS PhD</small>
          </Col>
        </Row>
        <br />
        <label>
          Select the species you are able to advise on <sup>*</sup>
        </label>

        <FormItem
          name="species"
          valuePropName="checked"
          rules={[
            { required: true, message: 'Please select at least 1 species!' },
          ]}
        >
          <Checkbox.Group>
            <Row gutter={16}>
              {SPECIES.map((spec) => (
                <Col span={6}>
                  <Checkbox value={spec}>{spec}</Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </FormItem>
        <label>Other species you are able to advise on</label>
        <Row>
          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
            <FormItem name="otherSpecies">
              <Input />
            </FormItem>
          </Col>
        </Row>
        <label>
          Proof of Identity document requirements
          <sup>*</sup>
        </label>
        <p style={{ color: '#878787' }}>
          Images of your valid (not expired) driver’s license must be uploaded
          in full colour (i.e. no black-and-white scans) and must be clear and
          large enough to read. Photocopies of identity documents are not
          acceptable. Both front and back of a driver's license or the entire
          personal information page of a passport. Documents can be uploaded in
          .png or .jpg format (photos and colour scans acceptable).
        </p>
        <Row>
          <Col span={24}>
            <FormItem name="license" valuePropName="fileList">
              <UploadFile
                maxFiles={4}
                uploadFile={uploadFile}
                setData={setdriverLicense}
                typeFile="image"
                loading={loadingImage}
                setLoading={setLoadingImage}
              />
              <p style={{ color: '#878787' }}>
                If you have a change of address on your license, please provide
                additional documentation such as a utility bill with your name
                and the new address.
              </p>
            </FormItem>
          </Col>
        </Row>
        <label>
          Your ABN <sup>*</sup>
        </label>
        <Row gutter={16}>
          <Col xs={12} sm={12} md={8} lg={8} xl={8}>
            <FormItem
              name="ABN"
              rules={[{ required: true, message: 'Please input your ABN!' }]}
            >
              <Input />
            </FormItem>
          </Col>
        </Row>
        <label>
          Are you registered for GST? <sup>*</sup>
        </label>
        <Row gutter={16}>
          <Col span={8}>
            <FormItem
              name="GST"
              rules={[{ required: true, message: 'Please input your GST!' }]}
            >
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </FormItem>
          </Col>
        </Row>
        <label>
          Your bank account details <sup>*</sup>
        </label>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
            <FormItem
              name="holderName"
              rules={[
                { required: true, message: 'Please input your holder name!' },
              ]}
            >
              <Input placeholder="Holder Name" />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={12} sm={12} md={8} lg={8} xl={8}>
            <FormItem
              name="BSB"
              rules={[{ required: true, message: 'Please input your BSB!' }]}
            >
              <Input placeholder="BSB" />
            </FormItem>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8} xl={8}>
            <FormItem
              name="accountNumber"
              rules={[
                {
                  required: true,
                  message: 'Please input your account number!',
                },
              ]}
            >
              <Input placeholder="Account Number" />
            </FormItem>
          </Col>
        </Row>
        <label>Comments/Questions</label>
        <Row>
          <Col span={24}>
            <FormItem name="note">
              <Input.TextArea rows={4} />
            </FormItem>
          </Col>
        </Row>
        <label>
          Confirmation <sup>*</sup>
        </label>
        <Row>
          <Col span={24}>
            <FormItem
              name="confirmation"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error('Please confirm your information')
                        ),
                },
              ]}
            >
              <Checkbox>
                I confirm the information provided is true, complete and
                accurate
              </Checkbox>
            </FormItem>
          </Col>
        </Row>
        <Button
          loading={loading || loadingImage}
          htmlType="submit"
          type="primary"
        >
          SUBMIT
        </Button>
      </Form>
    </RegistrationFormWrapper>
  );
};

export default RegistrationForm;
