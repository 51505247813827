export const SPECIES = [
  "Alpaca",
  "Bee",
  "Camel",
  "Cat",
  "Cattle",
  "Deer",
  "Dog",
  "Ferret",
  "Fish",
  "Goat",
  "Guinea pig",
  "Horse or donkey",
  "Pet bird",
  "Pig",
  "Poultry",
  "Rabbit",
  "Rat or mouse",
  "Reptile",
  "Sheep",
  "Turtle or tortoise",
  "Wildlife",
];

export const REGISTERED_STATE = [
  {
    name: "Not Registered",
    value: "NoRegistered",
  },
  {
    name: "Australian Capital Territory",
    value: "ACT",
  },
  {
    name: "New South Wales",
    value: "NSW",
  },
  {
    name: "Northern Territory",
    value: "NT",
  },
  {
    name: "Queensland",
    value: "QLD",
  },
  {
    name: "South Australia",
    value: "SA",
  },
  {
    name: "Tasmania",
    value: "TAS",
  },
  {
    name: "Victoria",
    value: "VIC",
  },
  {
    name: "Western Australia",
    value: "WA",
  },
];


export const STATE = [
  {
    name: "Australian Capital Territory",
    value: "ACT",
  },
  {
    name: "New South Wales",
    value: "NSW",
  },
  {
    name: "Northern Territory",
    value: "NT",
  },
  {
    name: "Queensland",
    value: "QLD",
  },
  {
    name: "South Australia",
    value: "SA",
  },
  {
    name: "Tasmania",
    value: "TAS",
  },
  {
    name: "Victoria",
    value: "VIC",
  },
  {
    name: "Western Australia",
    value: "WA",
  },
];

export const LIST_NATIONALITY = [{ value: "AU", label: "Australia" }];
