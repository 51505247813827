/* eslint-disable @typescript-eslint/no-unused-vars */
import { Upload, Row, Col, Button, message, Tooltip } from 'antd';
import React, { useState } from 'react';
import {
  EyeOutlined,
  DeleteOutlined,
  LoadingOutlined,
  FileOutlined,
} from '@ant-design/icons';
import { ModalWrapper, UploadImageWrapper } from './style';

interface UploadProps {
  maxFiles: number;
  uploadFile: any;
  typeFile: string;
}

async function getUrl(name: string, type: string, documentType: string) {
  let docType = '';
  const timeSubmit = Date.now();
  if (documentType === 'veterinary') {
    docType = 'VeterinaryRegistration';
  } else {
    docType = 'DriverLicense';
  }
  return fetch(
    `${process.env.GATSBY_API_URL}/application-assets?documentType=${docType}&fileType=${type}&fileName=${timeSubmit}-${name}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  ).then((res) => res.json());
}

const getFileLt100Mb = (file) => {
  return file.size / 1024 / 1024 < 100;
};
const UploadFile: FC<UploadProps> = ({ loading, setLoading, setData, ...props }) => {
  const [fileList, setFileList] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onChangeUpload = (e) => {
    if (getFileLt100Mb(e.file)) setFileList(e.fileList);
  };

  const customRequest = async ({ onSuccess, file, typeFile }) => {
    if (!getFileLt100Mb(file)) {
      message.error('File must smaller than 10MB!');
    } else {
      try {
        setLoading(true);
        const responseS3 = await getUrl(file.name, file.type, props.id);
        await props.uploadFile(
          responseS3?.data?.uploadUrl,
          file,
          responseS3?.data?.fields,
          props.id
        );
        onSuccess(responseS3?.data?.key);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleClickShowImage = (file) => () => {
    setCurrentImage(URL.createObjectURL(file));
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleClickDelete = (file) => () => {
    const index = fileList.indexOf(file);
    const newFileList = [...fileList];
    newFileList.splice(index, 1);
    setFileList(newFileList);
    setData(newFileList.map((file) => file?.response));
  };

  const handleClickShowFile = (file, name) => () => {
    const link = document.createElement('a');
    link.href = file;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  return (
    <UploadImageWrapper>
      {loading && (
        <div className="icon-spin">
          <LoadingOutlined />
        </div>
      )}
      <Upload.Dragger
        onChange={onChangeUpload}
        disabled={!props.maxFiles ? false : fileList?.length === props.maxFiles}
        customRequest={customRequest}
        showUploadList={false}
        fileList={fileList}
        {...(props?.typeFile === 'image'
          ? {
              accept: 'image/*',
            }
          : {
              accept: 'image/*, .doc, .docx, .pdf',
            })}
      >
        <p className="ant-upload-drag-icon">
          <svg
            viewBox="0 0 1024 1024"
            focusable="false"
            class=""
            data-icon="inbox"
            width="50px"
            height="50px"
            fill="#B1B1B1"
            aria-hidden="true"
          >
            <path d="M885.2 446.3l-.2-.8-112.2-285.1c-5-16.1-19.9-27.2-36.8-27.2H281.2c-17 0-32.1 11.3-36.9 27.6L139.4 443l-.3.7-.2.8c-1.3 4.9-1.7 9.9-1 14.8-.1 1.6-.2 3.2-.2 4.8V830a60.9 60.9 0 0 0 60.8 60.8h627.2c33.5 0 60.8-27.3 60.9-60.8V464.1c0-1.3 0-2.6-.1-3.7.4-4.9 0-9.6-1.3-14.1zm-295.8-43l-.3 15.7c-.8 44.9-31.8 75.1-77.1 75.1-22.1 0-41.1-7.1-54.8-20.6S436 441.2 435.6 419l-.3-15.7H229.5L309 210h399.2l81.7 193.3H589.4zm-375 76.8h157.3c24.3 57.1 76 90.8 140.4 90.8 33.7 0 65-9.4 90.3-27.2 22.2-15.6 39.5-37.4 50.7-63.6h156.5V814H214.4V480.1z"></path>
          </svg>
        </p>
        <p className="ant-upload-text">
          Click or drag {props.typeFile} to this area to upload.
        </p>
        <p className="ant-upload-hint">
          You can upload up to {props.maxFiles} {props.typeFile}s.
        </p>
      </Upload.Dragger>
      <Row className="image-row" gutter={20}>
        {fileList?.map((file) => (
          <div className="show-item">
            <div className="uploaded-image">
              {file?.type?.includes('image') ? (
                <img src={URL.createObjectURL(file?.originFileObj)} />
              ) : (
                <div className="uploaded-file">
                  <FileOutlined />
                </div>
              )}
              <div className="icon-row">
                <Button
                  className="icon-style"
                  {...(file?.type?.includes('image')
                    ? {
                        onClick: handleClickShowImage(file?.originFileObj),
                      }
                    : {
                        onClick: handleClickShowFile(
                          file?.originFileObj,
                          file?.name
                        ),
                      })}
                >
                  <EyeOutlined />
                </Button>
                <Button
                  className="icon-style"
                  onClick={handleClickDelete(file)}
                >
                  <DeleteOutlined />
                </Button>
              </div>
            </div>
            <Tooltip title={file?.name}>
              <p>{file?.name}</p>
            </Tooltip>
          </div>
        ))}
      </Row>
      <ModalWrapper
        width={600}
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        {currentImage && <img className="img" src={currentImage} />}
      </ModalWrapper>
    </UploadImageWrapper>
  );
};

export default UploadFile;
