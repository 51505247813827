import { notification } from 'antd';
import React from 'react';

interface DataRegisterProps {
  firstName: string;
  lastName: string;
  dob: string;
  middleName?: string;
  preferredName?: string;
  email: string;
  phoneNumber: string;
  alternativePhoneNumber: string;
  address: {
    addressLine1: string;
    addressLine2?: string;
    suburb: string;
    state: string;
    postCode: string;
    country: string;
  };
  registeredState: string;
  veterinaryRegistration: string[];
  registrationExpiryDate?: string;
  bio?: string;
  qualifications: string;
  supportedSpecies?: string[];
  driverLicense: string[];
  abn: string;
  gstRegistered: boolean;
  bankInformation: {
    holderName: string;
    bsb: string;
    accountNumber: string;
  };
  note: string;
}

export const apiRegister = function (data: DataRegisterProp) {
  return fetch(`${process.env.GATSBY_API_URL}/vet-register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response?.ok) {
        notification.error({
          message: 'FAIL',
          duration: 0,
          description: (
            <div>
              <div>Your application has NOT been submitted successfully.</div>
              <div>
                Please check if you correctly entered all the information
                required. Please contact the Phone A Vet support team at
                <b> support@phoneavet.com.au </b>
                to inform us of the problem you have encountered.
              </div>
            </div>
          ),
        });
      } else {
        notification.success({
          message: 'SUCCESS',
          description: (
            <div>
              <div>Your application has been submitted successfully!</div>
              <div>
                Thank you for your application. We will get back to you as soon
                as possible.
              </div>
              <div>Phone A Vet support team.</div>
            </div>
          ),
        });
      }
      return response.json();
    })
    .catch((error) => {
      console.error('Error:', error);
    });
};
