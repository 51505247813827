import React from 'react';
import Header from 'components/Header';
import { Layout } from 'antd';
import { Helmet } from 'react-helmet';
import FooterContact from 'components/FooterContact';
import GlobalStyle from '../configs/globalStyle';
import SEO from './seo';

interface LayoutWrapperProps {
  children: any;
  title?: string;
}

const LayoutWrapper: FC<LayoutWrapperProps> = ({ children, title }) => (
  <Layout>
    <Helmet>
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
      />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <SEO title={title} />
    <GlobalStyle />
    <Header />
    <Layout.Content>{children}</Layout.Content>
    <Layout.Footer className="footer">
      <FooterContact />
    </Layout.Footer>
  </Layout>
);

export default LayoutWrapper;
