import LayoutWrapper from 'components/LayoutWrapper';
import RegistrationForm from 'components/Registration/form';
import { CMSWrapper } from 'configs/globalStyle';
import React from 'react';

const RegistrationFormPage: FC = () => (
  <LayoutWrapper>
    <CMSWrapper>
      <RegistrationForm />
    </CMSWrapper>
  </LayoutWrapper>
);
export default RegistrationFormPage;
